<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">
                Intranet Systems<br />
              </h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Bringing your internal systems, processes and people together.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <div class="column is-size-4">
        <h1>
          We <span>design</span>, <span>develop</span>,
          <span>implement</span> and <span>support</span> Intranets of all
          shapes and sizes that have proven to significantly improve
          efficiencies in our client's work practices. <br /><br />
          We create Intranet platforms and systems that allow businesses to
          <span>manage</span> and <span>distribute</span> frequently required
          information efficiently, provide <span>secure</span> and
          <span>flexible</span> access to frequently accessed documents
          alongside improving communications and working relationships between
          staff members. <br /><br />
          Our Intranet solutions are <span>innovative</span>,
          <span>flexible</span>, <span>feature-rich</span> and
          <span>intuitive</span>. <br /><br />
          We have graciously received numerous awards and commendations for our
          excellence in providing Intranet solutions, awarded
          <span>two separate awards</span> in the
          <span>2007 Intranet Innovation awards</span> as well as being
          recognised by the
          <span>
            Royal Blind Society for excellence in accessibility features.
          </span>
        </h1>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/solutions-banner/intranet-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/solutions-banner/intranet-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Intranet"
}
</script>
